import React, { useState, useEffect } from "react";
import DefaulLayout from "../../components/DefaultLayout";
import TableActions from "../../components/TableActions";
import Buscador from "../../components/Buscador";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import api from "../../utils/api";
import paginationFactory from "react-bootstrap-table2-paginator";

function PacientesListar() {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [criteria, setCriteria] = useState("");
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "nombre",
      text: "Nombre",
    },
    {
      dataField: "telefono",
      text: "Teléfono",
    },
    {
      dataField: "fechaNacimiento",
      text: "Fecha de Nacimiento",
    },
    {
      dataField: "#",
      text: "Acciones",
      formatter: (cell, row) => {
        return <TableActions edit={{ url: "/usuarios/" }} rowId={row._id} />;
      },
    },
  ];

  useEffect(() => {
    api
      .listarUsuarios()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getUsuariosData = (data) => {
    if (criteria) {
      return data.filter((usuario) => {
        return (
          usuario.nombre?.toLowerCase().includes(criteria) ||
          usuario.dpi?.includes(criteria)
        );
      });
    }
    return data;
  };
  return (
    <DefaulLayout title="Usuarios">
      <div className="">
        <Buscador
          placeholder={
            " Puedes buscar por nombre o por número de DPI al empleado 😉"
          }
          value={criteria}
          onSearchChange={(event) => {
            setCriteria(event.target.value);
          }}
        />
        <div className="py-1"></div>
        {isLoading ? (
          <Spinner animation="grow" variant="info" />
        ) : (
          <BootstrapTable
            keyField="id"
            data={getUsuariosData(data)}
            columns={columns}
            pagination={paginationFactory()}
          />
        )}
      </div>
    </DefaulLayout>
  );
}
export default PacientesListar;

import React, { Component } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";

import Home from "./pages/Home";
import ChatBot from "./pages/ChatBot";
import Pacientes from "./pages/pacientes/PacientesCrear";
import Contacto from "./pages/Contacto";
import Login from "./pages/Login";
import Error from "./components/error";
import PacientesListar from "./pages/pacientes/PacientesListar";
import UsuariosListar from "./pages/usuarios/UsuariosListar";
import Usuarios from "./pages/usuarios/UsuariosCrear";
import PacientesActualizar from "./pages/pacientes/PacientesActualizar";
import UsuariosActualizar from "./pages/usuarios/UsuariosActualizar";
import ConsultaCrear from "./pages/consultas/ConsultaCrear";
import ConsultaActualizar from "./pages/consultas/ConsultaActualizar";
import api from "./utils/api";
import { json } from "react-router-dom";
import CitaCrear from "./pages/citas/CitaCrear";
import CitaListar from "./pages/citas/CitaListar";
import CitaActualizar from "./pages/citas/CitaActualizar";
import HospitalizacionCrear from "./pages/hospitalizaciones/HospitalizacionCrear";
import HospitalizacionActualizar from "./pages/hospitalizaciones/HospitalizacionActualizar";
import authProvider from "./utils/AuthProvider";

function requireAuth(loader) {
  return async (params) => {
    if (!authProvider.checkAuth()) {
      return redirect("/login");
    }
    return loader(params);
  };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/chatbot",
    element: <ChatBot />,
  },
  {
    path: "/contacto",
    element: <Contacto />,
  },
  {
    path: "/pacientes",
    element: <Pacientes />,
    loader: requireAuth(async () => {
      return json({});
    }),
  },
  {
    path: "/pacientes-listado",
    element: <PacientesListar />,
    loader: requireAuth(async () => {
      return json({});
    }),
  },
  {
    path: "/pacientes/:pacienteId",
    element: <PacientesActualizar />,

    loader: requireAuth(async ({ params }) => {
      const response = await api.getPaciente(params.pacienteId);
      return json(response.data, { status: 200 });
    }),
  },
  {
    path: "/pacientes/:pacienteId/consulta",
    element: <ConsultaCrear />,

    loader: async ({ params }) => {
      const response = await api.getPaciente(params.pacienteId);
      return json(response.data, { status: 200 });
    },
  },
  {
    path: "/pacientes/:pacienteId/consulta/:consultaId",
    element: <ConsultaActualizar />,

    loader: requireAuth(async ({ params }) => {
      const pacienteResponse = await api.getPaciente(params.pacienteId);
      const consultaResponse = await api.getConsulta(params.consultaId);
      return json(
        {
          paciente: pacienteResponse.data.paciente,
          consulta: consultaResponse.data.consulta,
        },
        { status: 200 }
      );
    }),
  },
  {
    path: "/pacientes/:pacienteId/hospitalizaciones",
    element: <HospitalizacionCrear />,

    loader: requireAuth(async ({ params }) => {
      const pacienteResponse = await api.getPaciente(params.pacienteId);
      return json(
        {
          paciente: pacienteResponse.data.paciente,
        },
        { status: 200 }
      );
    }),
  },
  {
    path: "/pacientes/:pacienteId/hospitalizacion/:hospitalizacionId",
    element: <HospitalizacionActualizar />,

    loader: requireAuth(async ({ params }) => {
      const pacienteResponse = await api.getPaciente(params.pacienteId);
      const HospitalizacionResponse = await api.getHospitalizacion(
        params.hospitalizacionId
      );
      return json(
        {
          paciente: pacienteResponse.data.paciente,
          hospitalizacion: HospitalizacionResponse.data.hospitalizacion,
        },
        { status: 200 }
      );
    }),
  },
  {
    path: "/pacientes/:pacienteId/hospitalizacion/:hospitalizacionId/dar-de-alta",
    element: <HospitalizacionActualizar />,

    loader: requireAuth(async ({ params }) => {
      const pacienteResponse = await api.getPaciente(params.pacienteId);
      const HospitalizacionResponse = await api.getHospitalizacion(
        params.hospitalizacionId
      );
      return json(
        {
          paciente: pacienteResponse.data.paciente,
          hospitalizacion: HospitalizacionResponse.data.hospitalizacion,
        },
        { status: 200 }
      );
    }),
  },
  {
    path: "/citas",
    element: <CitaCrear />,
    loader: requireAuth(async () => {
      return json({});
    }),
  },
  {
    path: "/citas-listado",
    element: <CitaListar />,
    loader: requireAuth(async () => {
      return json({});
    }),
  },
  {
    path: "/citas/:citaId",
    element: <CitaActualizar />,
    loader: requireAuth(async ({ params }) => {
      const response = await api.getCita(params.citaId);
      return json(response.data, { status: 200 });
    }),
  },

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/usuarios",
    element: <Usuarios />,
    loader: requireAuth(async () => {
      if (!authProvider.checkRoutePermissions("usuarios")) {
        return redirect("/");
      } else {
        return json({});
      }
    }),
  },
  {
    path: "/usuarios-listado",
    element: <UsuariosListar />,
    loader: requireAuth(async () => {
      if (!authProvider.checkRoutePermissions("usuarios")) {
        return redirect("/");
      } else {
        return json({});
      }
    }),
  },
  {
    path: "/usuarios/:usuarioId",
    element: <UsuariosActualizar />,
    loader: requireAuth(async ({ params }) => {
      if (!authProvider.checkRoutePermissions("usuarios")) {
        return redirect("/");
      } else {
        const response = await api.getUsuario(params.usuarioId);
        return json(response.data, { status: 200 });
      }
    }),
  },
  {
    path: "*",
    element: <Error />,
  },
]);
class Router extends Component {
  render() {
    return <RouterProvider router={router} />;
  }
}

export default Router;

// Se utiliza en PacientesActualizar.js

import React, { Component } from "react";

class CustomAccordion extends Component {
  render() {
    return (
      <div>
        <details open>
          <p className="descripcion">{this.props.children}</p>
        </details>
      </div>
    );
  }
}

export default CustomAccordion;

import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo-mtw-header.png";
import { NavLink, useNavigate } from "react-router-dom";
import authProvider from "../utils/AuthProvider";

function Header() {
  const navigate = useNavigate();
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleCloseSession = () => {
    authProvider.deleteSession();
    navigate("/");
  };

  return (
    <>
      <section>
        <header
          className={`header ${scrollDirection === "down" ? "hide" : ""}`}
        >
          <nav>
            <div className="title">
              <img className="logo-line" src={logo} alt="Logotipo" />
            </div>
            <div className="menu">
              <NavLink className="dropbtn" to="/">
                Inicio
              </NavLink>
              <NavLink className="dropbtn" to="/chatbot">
                ChatBot
              </NavLink>
              {authProvider.checkRoutePermissions("citas") ? (
                <div className="dropdown">
                  <input type="checkbox" id="dropdown-toggle-citas" />
                  <label className="dropbtn" htmlFor="dropdown-toggle-citas">
                    Citas
                  </label>
                  <div className="dropdown-content">
                    <NavLink to="/citas">Nueva Cita</NavLink>
                    <NavLink to="/citas-listado">Listar Citas</NavLink>
                  </div>
                </div>
              ) : null}
              {authProvider.checkRoutePermissions("pacientes") ? (
                <div className="dropdown">
                  <input type="checkbox" id="dropdown-toggle-pacientes" />
                  <label
                    className="dropbtn"
                    htmlFor="dropdown-toggle-pacientes"
                  >
                    Pacientes
                  </label>
                  <div className="dropdown-content">
                    <NavLink to="/pacientes">Nuevo Paciente</NavLink>
                    <NavLink to="/pacientes-listado">Listar Pacientes</NavLink>
                  </div>
                </div>
              ) : null}
              {authProvider.checkRoutePermissions("usuarios") ? (
                <div className="dropdown">
                  <input type="checkbox" id="dropdown-toggle-usuarios" />
                  <label className="dropbtn" htmlFor="dropdown-toggle-usuarios">
                    Usuarios
                  </label>
                  <div className="dropdown-content">
                    <NavLink to="/usuarios">Nuevo Usuario</NavLink>
                    <NavLink to="/usuarios-listado">Listar Usuarios</NavLink>
                  </div>
                </div>
              ) : null}
              {!!authProvider.checkAuth() ? (
                <button className="dropbtn danger" onClick={handleCloseSession}>
                  Cerrar Sesión
                </button>
              ) : (
                <NavLink className="dropbtn succes" to="/login">
                  Login
                </NavLink>
              )}
            </div>
          </nav>
        </header>
        <header className="blackHeader"></header>
      </section>
    </>
  );
}

export default Header;
